import React from 'react';
import wrapField from './wrapField';
import Prompt from './Prompt';
import FieldError from './FieldError';

const RadioGroupGrid = (props) => {
  const {
    field,
    meta: { error, touched, value },
    label,
    options,
    disabled,
    gridIndex,
    additionalInfo,
    moreText,
    ariaLabelledby,
  } = props;

  return (
    <div className="fields-grid">
      {gridIndex === 0 && (
        <div aria-hidden="true" className="option-ranges-box">
          {options.map((option) => (
            <div key={option.value} className="option-tab">
              {option.label}
            </div>
          ))}
        </div>
      )}

      <fieldset
        className="user-option-box user-option-box-noscroll clear"
        aria-labelledby={ariaLabelledby}
      >
        <Prompt
          {...{ label, additionalInfo, moreText }}
          overrideClass="option-left-label"
        />
        <ul
          className={`list-left-label ans-count-${options.length} rating-scale`}
        >
          {options.map((option, i) => (
            <li key={option.value}>
              <input
                {...field}
                name={field.name}
                type="radio"
                value={option.value.toString()}
                // eslint-disable-next-line eqeqeq
                checked={option.value == value}
                aria-labelledby={`label_${field.name}_option_${i}`}
                disabled={disabled}
                id={`${field.name}_option_${i}`}
              />
              <label
                id={`label_${field.name}_option_${i}`}
                htmlFor={`${field.name}_option_${i}`}
                aria-hidden // Make sure the label cant be individually read
              >
                {option.label}
              </label>
            </li>
          ))}
        </ul>
      </fieldset>
      <FieldError {...{ error, touched }} />
    </div>
  );
};

export default wrapField(RadioGroupGrid);
