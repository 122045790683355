export const EN_TRANSLATION = {
  valid_date: 'Please enter a valid date.',
  valid_year: 'Please enter a valid year.',
  valid_month: 'Please enter a valid month.',
  valid_day: 'Please enter a valid day.',
  valid_name: 'Please enter a valid name.',
  valid_mrn: 'Please enter a valid MRN.',
  valid_value: 'Please enter a valid value.',
  valid_phone_number: 'Please enter a valid phone number.',
  valid_area_code:
    'Please enter a valid area code starting with a number 2 - 9.',
  valid_required: 'Please fill out this field.',
  valid_email: 'Please enter a valid email address.',
  valid_checkbox: 'Please check this checkbox.',
  valid_min_value: 'Value must be greater than or equal to %{minValue}',
  valid_zip: 'Please enter a valid zip code.',
  valid_zip_format: 'Please enter a valid zip code (#####-####).',
  valid_suffix:
    'Please select valid suffix(es) or clear the suffix and consider adding a title.',
  valid_honorific:
    'Please select a valid title or select “No Title” and consider adding a suffix.',
  email_or_phone: 'Please select email and/or text.',
  authentication_form: 'Authentication Form',
  change_language: 'Change Language',
  notification_preferences: 'Notification Preferences',
  receive_notif_provider: 'Where should your patient receive notifications?',
  receive_notif_patient:
    'Where would you like to receive your notification(s)? You can update your email and phone number in the boxes below.',
  email: 'Email',
  phone: 'Phone',
  text_message: 'Text Message',
  add_com_consent_provider:
    'By adding an email/phone number, you are asserting patient consent to communicate through those channels.',
  error_receiving_email_patient:
    'This email address is not able to receive email messages. Please confirm the email and enter a valid email address.',
  error_receiving_email_provider:
    'This email address is not able to receive email messages. Please confirm the email with the patient and enter a valid email address.',
  error_receiving_text_patient:
    'This phone number is not able to receive text messages. Please confirm the number and enter a valid number.',
  error_receiving_text_provider:
    'This phone number is not able to receive text messages. Please confirm the number with the patient and enter a valid number.',
  notif_receive_provider: 'Which notification(s) should your patient receive?',
  notif_receive_patient: 'Which notification(s) would you like to receive?',
  appoint_confirm: 'Appointment Confirmation',
  appoint_confirm_details:
    'Message alerting you an appointment has been booked and any special instructions from your provider.',
  appoint_reminder: 'Appointment Reminder',
  appoint_reminder_details:
    'Message reminding you of any upcoming scheduled appointments.',
  appoint_cancel: 'Appointment Cancellation',
  appoint_cancel_details:
    'Message notifying you that a previously scheduled appointment has been cancelled.',
  send_assessment_link_provider: 'Send to Patient Assessment Link',
  send_assessment_link_patient: 'Assessment Link',
  send_assessment_link_details:
    'Message requesting you complete an assessment from your provider.',
  save_changes: 'Save Changes',
  enter_email_address: 'Enter email address',
  enter_mobile_number: 'Enter mobile phone number',
  sms_opt_out_patient:
    'You have opted our of text messages. To reactivate, please text START to %{contact_number} to opt back into text messaging.',
  sms_opt_out_provider:
    'This patient has opted out of text message alerts. To reactivate, the patient must text START to %{contact_number} to opt back into text messaging.',
  on_text: 'ON',
  off_text: 'OFF',
};

export const ES_TRANSLATION = {
  valid_date: 'Por favor ingrese un fecha válido.',
  valid_year: 'Por favor ingrese un año válido.',
  valid_month: 'Por favor ingrese un mes válido.',
  valid_day: 'Por favor ingrese un día válido.',
  valid_name: 'Por favor ingrese un nombre válido.',
  valid_mrn: 'Por favor ingrese un MRN válido.',
  valid_value: 'Por favor ingrese un valor válido.',
  valid_phone_number: 'Por favor ingrese un número de teléfono válido.',
  valid_area_code:
    'Ingrese un código de área válido que comience con un número del 2 al 9.',
  valid_required: 'Por favor complete este campo.',
  valid_email:
    'Por favor, introduce una dirección de correo electrónico válida.',
  valid_checkbox: 'Marque esta casilla de verificación.',
  valid_min_value: 'El valor debe ser mayor o igual que %{minValue}',
  valid_zip: 'Por favor ingrese un código postal válido.',
  valid_zip_format: 'Por favor ingrese un código postal válido (#####-####).',
  valid_suffix: 'Por favor seleccione sufijo(s) válido(s).',
  valid_honorific: 'Por favor seleccione un título válido.',
  email_or_phone:
    'Por favor seleccione correo electrónico y/o mensaje de texto.',
  authentication_form: 'Formulario de autenticación',
  change_language: 'Cambiar idioma',
  notification_preferences: 'Preferencias de notifiación',
  receive_notif_provider: '¿Dónde debería recibir notificaciones su paciente?',
  receive_notif_patient:
    '¿Dónde le gustaría recibir sus notificaciones? Puede actualizar su correo electrónico y número de teléfono en los cuadros a continuación.',
  email: 'correo electrónico',
  phone: 'Teléfono',
  text_message: 'Mensaje de texto',
  add_com_consent_provider:
    'Al agregar un correo electrónico/número de teléfono, usted afirma el consentimiento del paciente para comunicarse a través de esos canales.',
  error_receiving_email_patient:
    'Esta dirección de correo electrónico no puede recibir mensajes de correo electrónico. Confirme el correo electrónico e ingrese una dirección de correo electrónico válida.',
  error_receiving_email_provider:
    'Esta dirección de correo electrónico no puede recibir mensajes de correo electrónico. Confirme el correo electrónico con el paciente e ingrese una dirección de correo electrónico válida.',
  error_receiving_text_patient:
    'Este número de teléfono no puede recibir mensajes de texto. Confirme el número e ingrese un número válido.',
  error_receiving_text_provider:
    'Este número de teléfono no puede recibir mensajes de texto. Confirme el número con el paciente e ingrese un número válido.',
  notif_receive_provider: '¿Qué notificaciones debería recibir su paciente?',
  notif_receive_patient: '¿Qué notificaciones le gustaría recibir?',
  appoint_confirm: 'Confirmación de cita',
  appoint_confirm_details:
    'Mensaje que le avisa de que se ha reservado una cita y de cualquier instrucción especial de su proveedor.',
  appoint_reminder: 'Recordatorio de cita',
  appoint_reminder_details:
    'Mensaje que le recuerda las próximas citas programadas.',
  appoint_cancel: 'Cancelación de cita',
  appoint_cancel_details:
    'Mensaje notificándole que una cita previamente programada ha sido cancelada.',
  send_assessment_link_provider: 'Enviar al enlace de evaluación del paciente',
  send_assessment_link_patient: 'Enlace de evaluación',
  send_assessment_link_details:
    'Mensaje solicitándole que complete una evaluación de su proveedor.',
  save_changes: 'Guardar cambios',
  enter_email_address: 'Introduzca la dirección de correo electrónico',
  enter_mobile_number: 'Introduce el número de teléfono móvil',
  sms_opt_out_patient:
    'Has optado por nuestros mensajes de texto. Para reactivar, envíe un mensaje de texto con INICIO a %{contact_number} para volver a optar por la mensajería de texto.',
  sms_opt_out_provider:
    'This patient has opted out of text message alerts. To reactivate, the patient must text START to %{contact_number} to opt back into text messaging.',
  on_text: 'ENCENDER',
  off_text: 'APAGAR',
};

export class LanguageTranslator {
  static getTranslations = (locale) => {
    let translations;
    switch (locale) {
      case 'es':
        translations = ES_TRANSLATION;
        break;
      default:
        translations = EN_TRANSLATION;
        break;
    }
    return translations;
  };

  static translate = (locale, val) =>
    LanguageTranslator.getTranslations(locale)[val];
}
