import React, { useEffect, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import clsx from 'clsx';
import { textField, toggleSwitch } from '../../../../components/inputs';
import { noop, validations } from '../../../../helperFunctions';
import styles from './manage_communication_preferences.module.scss';
import {
  getNotificationPreferences,
  updateNotificationPreferences,
} from './apis';
import TextField from '../../../components/inputs/TextField';
import { LanguageTranslator } from '../../../../LanguageTranslator';

const CommunicationPreferenceForm = ({
  patientID,
  isPatientFacing = false,
  setLoader = noop,
  setLocale = noop,
  syncFormikToRedux = noop,
  locale,
}) => {
  const [fields, setFields] = useState({});
  const [metadata, setMetadata] = useState({});

  const handleFormikChange = (values, isValid) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      syncFormikToRedux(values, isValid); // Mark the form as dirty in Redux
    }, [isValid, values]);
  };

  const loadPreferences = (showLoader = true) => {
    if (showLoader) {
      setLoader(true);
    }
    getNotificationPreferences(patientID).then((r) => {
      setLoader(false);
      if (r && r.data) {
        setFields(r.data.fields);
        setMetadata(r.data.metadata);
        setLocale(r.data.metadata.locale);
      }
    });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoader(true);
    setSubmitting(true);
    const formData = {
      ...values,
      'patient_modal_via-email': values.via_email,
      'patient_modal_via-text': values.via_text,
      'appt_confirm_via-email': values.appt_via_email,
      'appt_confirm_via-text': values.appt_via_text,
      'assessment_link_via-email': values.at_home_via_email,
      'assessment_link_via-text': values.at_home_via_text,
      'appt_reminder_via-email': values.appt_reminder_via_email,
      'appt_reminder_via-text': values.appt_reminder_via_text,
      'notification-phone': values.notification_text,
      'notification-email': values.notification_email,
      patient_id: patientID,
    };
    updateNotificationPreferences(formData).then(() => {
      setSubmitting(false);
      loadPreferences(false);
    });
  };

  useEffect(() => {
    loadPreferences();
  }, [patientID]);

  const initialValues = () => {
    const values = {
      via_email: false,
      notification_email: '',
      via_text: false,
      notification_text: '',
      appt_via_email: false,
      appt_via_text: false,
      appt_reminder_via_email: false,
      appt_reminder_via_text: false,
      appt_cancel_via_email: false,
      appt_cancel_via_text: false,
      at_home_via_email: false,
      at_home_via_text: false,
    };
    if (fields) {
      Object.keys(fields).forEach((key) => {
        const field = fields[key];
        values[key] =
          field.default ||
          field.value ||
          (field.type === 'switch' ? false : '');
      });
    }
    return values;
  };

  const validate = (values) => {
    const errors = {};
    const emailRequiredValidation = validations.required(
      values.notification_email,
      locale,
    );
    const emailFormatValidation = validations.email(
      values.notification_email,
      locale,
    );
    const phoneRequiredValidation = validations.required(
      values.notification_text,
      locale,
    );
    const phoneFormatValidation = validations.phone(
      values.notification_text,
      locale,
    );

    if (values.via_email) {
      if (fields.notification_email.required) {
        if (emailRequiredValidation || emailFormatValidation) {
          errors.notification_email =
            emailRequiredValidation || emailFormatValidation;
        }
      } else if (emailFormatValidation) {
        errors.notification_email = emailFormatValidation;
      }
    }
    if (values.via_text) {
      if (fields.notification_text.required) {
        if (phoneRequiredValidation || phoneFormatValidation) {
          errors.notification_text =
            phoneRequiredValidation || phoneFormatValidation;
        }
      } else if (phoneFormatValidation) {
        errors.notification_text = phoneFormatValidation;
      }
    }
    return errors;
  };

  const translated = useMemo(() => {
    if (locale) {
      return LanguageTranslator.getTranslations(locale);
    }
    return {};
  }, [locale]);

  return fields ? (
    <Formik
      initialValues={initialValues()}
      validate={validate}
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, dirty, values, setFieldValue }) => {
        handleFormikChange(values, isValid);
        return (
          <Form role="form" className={styles.comFormWrapper}>
            <div className={styles.comFormInner}>
              <div className={styles.comFormInner}>
                <div className={styles.comMethodsWrapper}>
                  <i className={styles.italicText}>
                    {isPatientFacing
                      ? translated.receive_notif_patient
                      : translated.receive_notif_provider}
                  </i>
                  <div className={styles.comMethodContainer}>
                    <Field
                      label={translated.email}
                      name="via_email"
                      onText={translated.on_text}
                      offText={translated.off_text}
                      wrapperClass="mid-width-label-switch"
                      component={toggleSwitch}
                      skipDefault
                      disabled={patientID ? !metadata.edit_allowed : false}
                      onChange={(newValue) => {
                        if (newValue === false) {
                          [
                            'appt_via_email',
                            'appt_reminder_via_email',
                            'appt_cancel_via_email',
                            'at_home_via_email',
                          ].forEach((f) => {
                            setFieldValue(f, false);
                          });
                        }
                      }}
                    />
                    <div className={styles.comFieldCont}>
                      <TextField
                        label=""
                        name="notification_email"
                        placeholder={translated.enter_email_address}
                        component={textField}
                        mask="email"
                        fieldWrapClass={styles.w100}
                        className={clsx(styles.w100, 'input-f')}
                        errorClass={clsx('error', styles.fieldError)}
                        disabled={
                          !values.via_email ||
                          (patientID ? !metadata.edit_allowed : false)
                        }
                      />
                      {metadata &&
                        Object.keys(metadata).length > 0 &&
                        !metadata.notification_email_status && (
                          <p className={styles.errorText} id="emailStatus">
                            *{' '}
                            {isPatientFacing
                              ? translated.error_receiving_email_patient
                              : translated.error_receiving_email_provider}
                          </p>
                        )}
                    </div>
                  </div>
                  {metadata.allow_text_notification && (
                    <div className={styles.comMethodContainer}>
                      <Field
                        label={translated.text_message}
                        name="via_text"
                        onText={translated.on_text}
                        offText={translated.off_text}
                        wrapperClass="mid-width-label-switch"
                        component={toggleSwitch}
                        skipDefault
                        disabled={
                          patientID
                            ? !metadata.edit_allowed || metadata.text_opt_out
                            : false
                        }
                        onChange={(newValue) => {
                          if (newValue === false) {
                            [
                              'appt_via_text',
                              'appt_reminder_via_text',
                              'appt_cancel_via_text',
                              'at_home_via_text',
                            ].forEach((f) => {
                              setFieldValue(f, false);
                            });
                          }
                        }}
                      />
                      <div className={styles.comFieldCont}>
                        <TextField
                          label=""
                          name="notification_text"
                          placeholder={translated.enter_mobile_number}
                          mask="phone"
                          fieldWrapClass={styles.w100}
                          className={clsx(styles.w100, 'input-f')}
                          errorClass={clsx('error', styles.fieldError)}
                          disabled={
                            !values.via_text ||
                            (patientID ? !metadata.edit_allowed : false)
                          }
                        />
                        {metadata &&
                          Object.keys(metadata).length > 0 &&
                          !metadata.notification_text_status && (
                            <p className={styles.errorText} id="textStatus">
                              *{' '}
                              {isPatientFacing
                                ? translated.error_receiving_text_patient
                                : translated.error_receiving_text_provider}
                            </p>
                          )}
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.infoBanners}>
                  {metadata.allow_text_notification &&
                    metadata.text_opt_out && (
                      <div className={styles.optOutLabel}>
                        {isPatientFacing
                          ? translated.sms_opt_out_patient?.replace(
                              '%{contact_number}',
                              metadata.twillio_number,
                            )
                          : translated.sms_opt_out_provider?.replace(
                              '%{contact_number}',
                              metadata.twillio_number,
                            )}
                      </div>
                    )}
                  {!isPatientFacing && (
                    <div className={styles.alertLabel}>
                      {translated.add_com_consent_provider}
                    </div>
                  )}
                </div>
                <div className={styles.comPreferenceContainer}>
                  <i className={styles.italicText}>
                    {isPatientFacing
                      ? translated.notif_receive_patient
                      : translated.notif_receive_provider}
                  </i>
                  <div className={styles.comPreferencesWrapper}>
                    <div className={styles.comPreference}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={styles.labelBlack}>
                        {translated.appoint_confirm}
                      </label>
                      <div className={styles.inputAndMsgCont}>
                        {isPatientFacing && (
                          <p className={styles.parag}>
                            {translated.appoint_confirm_details}
                          </p>
                        )}
                        <div className={styles.inputCont}>
                          <Field
                            label={translated.email}
                            name="appt_via_email"
                            onText={translated.on_text}
                            offText={translated.off_text}
                            wrapperClass="mid-width-label-switch"
                            component={toggleSwitch}
                            skipDefault
                            disabled={
                              !values.via_email ||
                              (patientID ? !metadata.edit_allowed : false)
                            }
                          />
                          {metadata.allow_text_notification && (
                            <Field
                              label={translated.text_message}
                              name="appt_via_text"
                              onText={translated.on_text}
                              offText={translated.off_text}
                              wrapperClass="mid-width-label-switch"
                              component={toggleSwitch}
                              skipDefault
                              disabled={
                                !values.via_text ||
                                (patientID ? !metadata.edit_allowed : false)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.comPreference}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={styles.labelBlack}>
                        {translated.appoint_reminder}
                      </label>
                      <div className={styles.inputAndMsgCont}>
                        {isPatientFacing && (
                          <p className={styles.parag}>
                            {translated.appoint_reminder_details}
                          </p>
                        )}
                        <div className={styles.inputCont}>
                          <Field
                            label={translated.email}
                            name="appt_reminder_via_email"
                            onText={translated.on_text}
                            offText={translated.off_text}
                            wrapperClass="mid-width-label-switch"
                            component={toggleSwitch}
                            skipDefault
                            disabled={
                              !values.via_email ||
                              (patientID ? !metadata.edit_allowed : false)
                            }
                          />
                          {metadata.allow_text_notification && (
                            <Field
                              label={translated.text_message}
                              name="appt_reminder_via_text"
                              onText={translated.on_text}
                              offText={translated.off_text}
                              wrapperClass="mid-width-label-switch"
                              component={toggleSwitch}
                              skipDefault
                              disabled={
                                !values.via_text ||
                                (patientID ? !metadata.edit_allowed : false)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.comPreference}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={styles.labelBlack}>
                        {translated.appoint_cancel}
                      </label>
                      <div className={styles.inputAndMsgCont}>
                        {isPatientFacing && (
                          <p className={styles.parag}>
                            {translated.appoint_cancel_details}
                          </p>
                        )}
                        <div className={styles.inputCont}>
                          <Field
                            label={translated.email}
                            name="appt_cancel_via_email"
                            onText={translated.on_text}
                            offText={translated.off_text}
                            wrapperClass="mid-width-label-switch"
                            component={toggleSwitch}
                            skipDefault
                            disabled={
                              !values.via_email ||
                              (patientID ? !metadata.edit_allowed : false)
                            }
                          />
                          {metadata.allow_text_notification && (
                            <Field
                              label={translated.text_message}
                              name="appt_cancel_via_text"
                              onText={translated.on_text}
                              offText={translated.off_text}
                              wrapperClass="mid-width-label-switch"
                              component={toggleSwitch}
                              skipDefault
                              disabled={
                                !values.via_text ||
                                (patientID ? !metadata.edit_allowed : false)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.comPreference}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={styles.labelBlack}>
                        {isPatientFacing
                          ? translated.send_assessment_link_patient
                          : translated.send_assessment_link_provider}
                      </label>
                      <div className={styles.inputAndMsgCont}>
                        {isPatientFacing && (
                          <p className={styles.parag}>
                            {translated.send_assessment_link_details}
                          </p>
                        )}
                        <div className={styles.inputCont}>
                          <Field
                            label={translated.email}
                            name="at_home_via_email"
                            onText={translated.on_text}
                            offText={translated.off_text}
                            wrapperClass="mid-width-label-switch"
                            component={toggleSwitch}
                            skipDefault
                            disabled={
                              !values.via_email ||
                              (patientID ? !metadata.edit_allowed : false)
                            }
                          />
                          {metadata.allow_text_notification && (
                            <Field
                              label={translated.text_message}
                              name="at_home_via_text"
                              onText={translated.on_text}
                              offText={translated.off_text}
                              wrapperClass="mid-width-label-switch"
                              component={toggleSwitch}
                              skipDefault
                              disabled={
                                !values.via_text ||
                                (patientID ? !metadata.edit_allowed : false)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isPatientFacing && (
              <div className={clsx(styles.comModalFooter, 'modal-footer')}>
                {(patientID ? metadata.edit_allowed : true) && (
                  <button
                    type="submit"
                    disabled={isSubmitting || !isValid || !dirty}
                    className={`btn btn-primary ${styles.comSubmitBtn} ${
                      !isValid ? 'inactive' : ''
                    }`}
                  >
                    {translated.save_changes}
                  </button>
                )}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  ) : null;
};

export default CommunicationPreferenceForm;
